import React from "react";

type IntervalButtonProps = {
  value: string;
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const IntervalButton = (props: IntervalButtonProps) => {
  return (
    <button
      className={"cursor-pointer hover:text-blue-700 hover:font-semibold w-7 " + (props.selected ? "text-blue-700 font-semibold" : "")}
      onClick={props.onClick}
    >
      {props.value}
    </button>
  );
};

export default IntervalButton;
