import React, { useState } from "react";

type InputFieldProps = {
  labelLeft: string;
  labelRight: string;
  value: string;
  disabled: boolean;
};

const InputField = (props: InputFieldProps) => {
  const [value, setValue] = useState<string>(props.value);

  function onInputChange(value: string) {
    setValue(value);
  }

  return (
    <div
      className={
        "inline-flex w-full relative items-center border rounded mb-3 py-3 text-xs text-gray-800 " +
        (props.disabled ? "bg-gray-50" : "bg-white")
      }
    >
      <div className={"shrink px-3 " + (props.disabled ? "text-gray-400" : "text-gray-300")}>{props.labelLeft}</div>
      <input
        className={
          "grow text-right border-0 outline-none " + (props.disabled ? "bg-gray-50" : "bg-white")
        }
        type="text"
        value={value}
        onChange={(e) => onInputChange(e.target.value)}
      ></input>
      <div className="shrink-0 pl-2 pr-2">{props.labelRight}</div>
    </div>
  );
};

export default InputField;
