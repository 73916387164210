import React, { useEffect, useState } from "react";
import { getSymbolPriceTicker } from "../../../../utils/binance/api";
import InputField from "./input-field";

export enum ControlInputType {
  LIMIT,
  MARKET,
  STOP_LIMIT,
}

type ControlInputProps = {
  type: ControlInputType;
  isBuying: boolean;
  _1stCoin: string;
  _2ndCoin: string;
};

const ControlInput = (props: ControlInputProps) => {
  const [price, setPrice] = useState<number>(-1);

  useEffect(() => {
    getSymbolPriceTicker(`${props._1stCoin}${props._2ndCoin}`, function (price) {
      setPrice(price);
    });
  }, []);

  return (
    <div className="w-full">
      <div className="flex w-full justify-between text-xs mb-1 pr-3">
        <div className="text-gray-500">Avbl</div>
        <div className="text-gray-500">- {props._2ndCoin}</div>
      </div>
      {props.type === ControlInputType.STOP_LIMIT && (
        <InputField labelLeft="Stop" labelRight={props._2ndCoin} disabled={false} value="" />
      )}
      {price >= 0 && (
        <InputField
          labelLeft={props.type === ControlInputType.STOP_LIMIT ? "Limit" : "Price"}
          labelRight={props._2ndCoin}
          disabled={props.type === ControlInputType.MARKET ? true : false}
          value={props.type === ControlInputType.MARKET ? "Market" : `${Number(price).toFixed(2)}`}
        />
      )}
      <InputField disabled={false} labelLeft="Amount" labelRight={props._1stCoin} value={""} />
      <div className="py-2">
        <input
          readOnly={true}
          type="range"
          min={0}
          max={100}
          step={1}
          value={0}
          className="w-full"
        ></input>
      </div>
      <button
        className={
          "w-full border rounded text-sm py-3 text-white font-medium mt-2 opacity-80 " +
          (props.isBuying ? "bg-green-600" : "bg-red-500")
        }
      >
        {props.isBuying ? `Buy ${props._1stCoin}` : `Sell ${props._1stCoin}`}
      </button>
    </div>
  );
};

export default ControlInput;
